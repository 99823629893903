// @ts-nocheck we are going to come back and check each in-use file
(() => {
    $("._nonsubmit_tooltip").on("mouseout blur", function () {
        $("._nonsubmit_tooltip-content").removeClass("visible opacity-100");
        $("._nonsubmit_tooltip-content").addClass("invisible opacity-0");
    });
    $("._nonsubmit_tooltip").on("mouseover focus", function () {
        $("._nonsubmit_tooltip-content").removeClass("invisible opacity-0");
        $("._nonsubmit_tooltip-content").addClass("visible opacity-100");
    });
    let selectedDrop = null;
    let inputIsSelected = false;
    $("._nonsubmit_tooltip").click(function (e) {
        e.preventDefault();
        if (this! == selectedDrop) {
            selectedDrop = this;
            inputIsSelected = false;
        }
        if (!inputIsSelected) {
            $("._nonsubmit_tooltip").focus();
            inputIsSelected = true;
        } else {
            $("._nonsubmit_tooltip").blur();
            inputIsSelected = false;
        }
    });
})();

export {};
